<template>
  <div ref="hazopDashboard" class="row bg-white">
    <!-- <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"> -->
    <div class="col-2">
      <c-card title="공정&도면 목록" class="cardClassDetailForm dashborad">
        <template slot="card-button">
          <q-btn-group outline >
            <q-btn
              flat
              dense
              color="grey-6"
              :icon="fullscreen ? 'fullscreen_exit' : 'fullscreen'"
              class="tabletopicon tablefullscreen"
              @click="clickFullScreen">
              <q-tooltip v-if="fullscreen">
                원래대로
              </q-tooltip>
              <q-tooltip v-else>
                전체화면
              </q-tooltip>
            </q-btn>
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-12">
            <el-cascader-panel :options="options" @change="changePid">
              <template slot-scope="scope">
                <span>{{ scope.data.label }}</span>
                <span v-if="scope.data.isAssess">
                  <q-icon name="handyman" color="red-6" class="blinking" />
                </span>
              </template>
            </el-cascader-panel>
          </div>
        </template>
      </c-card>
    </div>
    <div class="col-10">
      <div class="row">
        <div class="col-10">
          <c-table
            ref="table"
            title="노드에 따른 이탈"
            tableId="hazop01"
            :columns="grid.columns"
            :data="grid.data"
            :gridHeight="grid.height"
            :merge="grid.merge"
            :filtering="false"
            :columnSetting="false"
            :usePaging="false"
            :editable="false"
            @linkClick="linkClick"
          >
          </c-table>
        </div>
        <div class="col-2">
          <div class="main-card card">
            <div class="grid-menu grid-menu-3col">
              <div class="no-gutters row">
                <div class="col-12">
                  <div class="widget-chart bg-grey-6 text-white widget-chart">
                    도면 정보
                  </div>
                </div>
                <div class="col-sm-6 col-xl-12">
                  <div class="widget-chart bg-warning text-white widget-chart-hover">
                    <div class="icon-wrapper rounded">
                      <div class="icon-wrapper-bg bg-white opacity-2"></div>
                      <i class="pe-7s-share text-white"></i>
                    </div>
                    <div class="widget-numbers">
                      <ICountUp :startVal="0" :endVal="pid.nodeCnt" :decimals="0"
                        :duration="4" :options="countOptions" />
                    </div>
                    <div class="widget-subheading">평가 노드</div>
                  </div>
                </div>
                <div class="col-sm-6 col-xl-12">
                  <div class="widget-chart bg-danger text-white widget-chart-hover">
                    <div class="icon-wrapper rounded">
                      <div class="icon-wrapper-bg bg-white opacity-2"></div>
                      <q-icon 
                        name="new_releases"
                        coloe='white'
                      />
                    </div>
                    <div class="widget-numbers">
                      <ICountUp :startVal="0" :endVal="pid.riskbookCnt" :decimals="0"
                        :duration="4" :options="countOptions" />
                    </div>
                    <div class="widget-subheading">위험등록부 등재</div>
                  </div>
                </div>
                <div class="col-sm-6 col-xl-12">
                  <div class="widget-chart bg-info text-white widget-chart-hover">
                    <div class="icon-wrapper rounded">
                      <div class="icon-wrapper-bg bg-white opacity-2"></div>
                      <q-icon 
                        name="build"
                        coloe='white'
                      />
                    </div>
                    <div class="widget-numbers">
                      <ICountUp :startVal="0" :endVal="pid.improvingCnt" :decimals="0"
                        :duration="4" :options="countOptions" />
                    </div>
                    <div class="widget-subheading">개선 진행중</div>
                  </div>
                </div>
                <div class="col-sm-6 col-xl-12">
                  <div class="widget-chart bg-success text-white widget-chart-hover">
                    <div class="icon-wrapper rounded">
                      <div class="icon-wrapper-bg bg-white opacity-2"></div>
                      <q-icon 
                        name="done"
                        coloe='white'
                      />
                    </div>
                    <div class="widget-numbers">
                      <ICountUp :startVal="0" :endVal="pid.imporveCompleteCnt" :decimals="0"
                        :duration="4" :options="countOptions" />
                    </div>
                    <div class="widget-subheading">개선완료</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <c-card :noHeader="true" class="cardClassDetailForm dashborad">
            <template slot="card-detail">
              <div class="col-4">
                <apexchart 
                  ref="chart1" 
                  height="250" 
                  type="bar"
                  :width="chart1.chartWidth"
                  :options="chart1.chartOptions" 
                  :series="chart1.series"></apexchart>
              </div>
              <div class="col-4">
                <apexchart 
                  ref="chart2" 
                  height="250" 
                  type="bar"
                  :width="chart2.chartWidth"
                  :options="chart2.chartOptions" 
                  :series="chart2.series"></apexchart>
              </div>
              <div class="col-4">
                <apexchart 
                  ref="chart3" 
                  height="250" 
                  type="bar"
                  :width="chart3.chartWidth"
                  :options="chart3.chartOptions" 
                  :series="chart3.series"></apexchart>
              </div>
            </template>
          </c-card>
        </div>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import ICountUp from "vue-countup-v2";
import VueApexCharts from "vue-apexcharts";
export default {
  name: 'hazop-dashboard',
  components: {
    ICountUp,
    apexchart: VueApexCharts
  },
  data() {
    return {
      fullscreen: false,
      pid: {
        nodeCnt: 0,
        riskbookCnt: 0,
        improvingCnt: 0,
        imporveCompleteCnt: 0,
      },
      options: [
        {
          value: 'a',
          label: '공정A',
          isAssess: true,
          children: [
            {
              value: '1',
              label: '도면1',
              isAssess: true,
            }, 
            {
              value: '2',
              label: '도면2',
              isAssess: true,
            }
          ]
        },
        {
          value: 'b',
          label: '공정B',
          children: [
            {
              value: '3',
              label: '도면3',
            }, 
          ]
        },
        {
          value: 'c',
          label: '공정C',
          isAssess: true,
          children: [
            {
              value: '5',
              label: '도면5',
            }, 
            {
              value: '6',
              label: '도면6',
              isAssess: true,
            }
          ]
        },
        {
          value: 'd',
          label: '공정D',
          isAssess: true,
          children: [
            {
              value: '7',
              label: '도면7',
            }, 
            {
              value: '8',
              label: '도면8',
              isAssess: true,
            }
          ]
        },
        {
          value: 'd',
          label: '공정D',
          children: [
            {
              value: '7',
              label: '도면7',
            }, 
            {
              value: '8',
              label: '도면8',
            }
          ]
        },
        {
          value: 'd',
          label: '공정D',
          children: [
            {
              value: '7',
              label: '도면7',
            }, 
            {
              value: '8',
              label: '도면8',
            }
          ]
        },
        {
          value: 'd',
          label: '공정D',
          children: [
            {
              value: '7',
              label: '도면7',
            }, 
            {
              value: '8',
              label: '도면8',
            }
          ]
        },
        {
          value: 'd',
          label: '공정D',
          children: [
            {
              value: '7',
              label: '도면7',
            }, 
            {
              value: '8',
              label: '도면8',
            }
          ]
        },
        {
          value: 'd',
          label: '공정D',
          children: [
            {
              value: '7',
              label: '도면7',
            }, 
            {
              value: '8',
              label: '도면8',
            }
          ]
        },
        {
          value: 'd',
          label: '공정D',
          children: [
            {
              value: '7',
              label: '도면7',
            }, 
            {
              value: '8',
              label: '도면8',
            }
          ]
        },
        {
          value: 'd',
          label: '공정D',
          children: [
            {
              value: '7',
              label: '도면7',
            }, 
            {
              value: '8',
              label: '도면8',
            }
          ]
        },
        {
          value: 'd',
          label: '공정D',
          children: [
            {
              value: '7',
              label: '도면7',
            }, 
            {
              value: '8',
              label: '도면8',
            }
          ]
        },
        {
          value: 'd',
          label: '공정D',
          children: [
            {
              value: '7',
              label: '도면7',
            }, 
            {
              value: '8',
              label: '도면8',
            }
          ]
        },
        {
          value: 'd',
          label: '공정D',
          children: [
            {
              value: '7',
              label: '도면7',
            }, 
            {
              value: '8',
              label: '도면8',
            }
          ]
        },
        {
          value: 'd',
          label: '공정D',
          children: [
            {
              value: '7',
              label: '도면7',
            }, 
            {
              value: '8',
              label: '도면8',
            }
          ]
        },
        {
          value: 'd',
          label: '공정D',
          children: [
            {
              value: '7',
              label: '도면7',
            }, 
            {
              value: '8',
              label: '도면8',
            }
          ]
        },
        {
          value: 'd',
          label: '공정D',
          children: [
            {
              value: '7',
              label: '도면7',
            }, 
            {
              value: '8',
              label: '도면8',
            }
          ]
        },
        {
          value: 'd',
          label: '공정D',
          children: [
            {
              value: '7',
              label: '도면7',
            }, 
            {
              value: '8',
              label: '도면8',
            }
          ]
        },
        {
          value: 'd',
          label: '공정D',
          children: [
            {
              value: '7',
              label: '도면7',
            }, 
            {
              value: '8',
              label: '도면8',
            }
          ]
        },
        {
          value: 'd',
          label: '공정D',
          children: [
            {
              value: '7',
              label: '도면7',
            }, 
            {
              value: '8',
              label: '도면8',
            }
          ]
        },
        {
          value: 'd',
          label: '공정D',
          children: [
            {
              value: '7',
              label: '도면7',
            }, 
            {
              value: '8',
              label: '도면8',
            }
          ]
        },
        {
          value: 'd',
          label: '공정D',
          children: [
            {
              value: '7',
              label: '도면7',
            }, 
            {
              value: '8',
              label: '도면8',
            }
          ]
        },
        {
          value: 'd',
          label: '공정D',
          children: [
            {
              value: '7',
              label: '도면7',
            }, 
            {
              value: '8',
              label: '도면8',
            }
          ]
        },
        {
          value: 'd',
          label: '공정D',
          children: [
            {
              value: '7',
              label: '도면7',
            }, 
            {
              value: '8',
              label: '도면8',
            }
          ]
        },
        {
          value: 'd',
          label: '공정D',
          children: [
            {
              value: '7',
              label: '도면7',
            }, 
            {
              value: '8',
              label: '도면8',
            }
          ]
        },
        {
          value: 'd',
          label: '공정D',
          children: [
            {
              value: '7',
              label: '도면7',
            }, 
            {
              value: '8',
              label: '도면8',
            }
          ]
        },
        {
          value: 'd',
          label: '공정D',
          children: [
            {
              value: '7',
              label: '도면7',
            }, 
            {
              value: '8',
              label: '도면8',
            }
          ]
        },
        {
          value: 'd',
          label: '공정D',
          children: [
            {
              value: '7',
              label: '도면7',
            }, 
            {
              value: '8',
              label: '도면8',
            }
          ]
        },
        {
          value: 'd',
          label: '공정D',
          children: [
            {
              value: '7',
              label: '도면7',
            }, 
            {
              value: '8',
              label: '도면8',
            }
          ]
        },
        {
          value: 'd',
          label: '공정D',
          children: [
            {
              value: '7',
              label: '도면7',
            }, 
            {
              value: '8',
              label: '도면8',
            }
          ]
        },
        {
          value: 'd',
          label: '공정D',
          children: [
            {
              value: '7',
              label: '도면7',
            }, 
            {
              value: '8',
              label: '도면8',
            }
          ]
        },
        {
          value: 'd',
          label: '공정D',
          children: [
            {
              value: '7',
              label: '도면7',
            }, 
            {
              value: '8',
              label: '도면8',
            }
          ]
        },
        {
          value: 'd',
          label: '공정D',
          children: [
            {
              value: '7',
              label: '도면7',
            }, 
            {
              value: '8',
              label: '도면8',
            }
          ]
        },
        {
          value: 'd',
          label: '공정D',
          children: [
            {
              value: '7',
              label: '도면7',
            }, 
            {
              value: '8',
              label: '도면8',
            }
          ]
        },
      ],
      grid: {
        height: '600px',
        merge: [
          { index: 0, colName: 'nodeNm' },
          { index: 1, colName: 'col0' },
          { index: 2, colName: 'col0' },
        ],
        columns: [
          {
            name: 'nodeNm',
            field: 'nodeNm',
            description: 'nodeNo',
            label: '노드',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'col1',
            field: 'col1',
            label: '이탈',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'col2',
            field: 'col2',
            label: '원인',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'result',
            field: 'result',
            label: '결과',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'col31',
            field: 'col31',
            label: '개선전',
            align: 'center',
            child: [
              {
                name: 'col32',
                field: 'col32',
                label: '빈도',
                align: 'center',
                style: 'width:40px',
                sortable: false
              },
              {
                name: 'col33',
                field: 'col33',
                label: '강도',
                align: 'center',
                style: 'width:40px',
                sortable: false
              },
              {
                name: 'col34',
                field: 'col34',
                label: '위험도',
                align: 'center',
                style: 'width:60px',
                sortable: false,
              },
            ]
          },
          {
            name: 'col35',
            field: 'col35',
            label: '개선후',
            align: 'center',
            child: [
              {
                name: 'col36',
                field: 'col36',
                label: '빈도',
                align: 'center',
                style: 'width:40px',
                sortable: false
              },
              {
                name: 'col37',
                field: 'col37',
                label: '강도',
                align: 'center',
                style: 'width:40px',
                sortable: false
              },
              {
                name: 'col38',
                field: 'col38',
                label: '위험도',
                align: 'center',
                style: 'width:60px',
                sortable: false,
              },
            ]
          },
          {
            name: 'col6',
            field: 'col6',
            label: '평가일',
            align: 'center',
            style: 'width:90px',
            sortable: false,
          },
          {
            name: 'col7',
            field: 'col7',
            label: '평가자',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
        ],
        data: [],
        allData: [],
      },
      countOptions: {
        useEasing: true,
        useGrouping: true,
        separator: ",",
        decimal: ".",
        prefix: "",
        suffix: "건"
      },
      chart1: {
        chartOptions: {
          title: {
            text: '평가도면별 노드 진행중'
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded'
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          xaxis: {
            categories: ['평가 노드'],
          },
          yaxis: {
            title: {
              text: '건'
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return "$ " + val + " 건"
              }
            }
          }
        },
        series: [{
          name: '진행중인 노드',
          data: [0]
        }, {
          name: '평가완료된 노드',
          data: [0]
        }],
        chartWidth: '80%',
      },
      chart2: {
        chartOptions: {
          title: {
            text: '평가도면별 위험등록부 등재수 비교[최근 평가 비교]'
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded'
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          xaxis: {
            categories: ['위험등록부 등재'],
          },
          yaxis: {
            title: {
              text: '건'
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return "$ " + val + " 건"
              }
            }
          }
        },
        series: [{
          name: '최근평가',
          data: [0]
        }, {
          name: '마지막 평가',
          data: [0]
        }],
        chartWidth: '80%',
      },
      chart3: {
        chartOptions: {
          title: {
            text: '평가도면별 개선요청수 비교[최근 평가 비교]'
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded'
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          xaxis: {
            categories: ['개선요청'],
          },
          yaxis: {
            title: {
              text: '건'
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return "$ " + val + " 건"
              }
            }
          }
        },
        series: [{
          name: '최근평가',
          data: [0]
        }, {
          name: '마지막 평가',
          data: [0]
        }],
        chartWidth: '80%',
      },
      editable: true,
      searchUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    window.addEventListener('resize', this.setSize);
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setSize);
  },
  methods: {
    init() {
      this.clickFullScreen();
      // role setting
      // url setting
      // code setting
      // list setting
      this.getList();
      this.setChart();
    },
    getList() {
      this.grid.allData = [
        { 
          pidId: '6', 
          nodeNm: '노드1', 
          nodeNo: '노드번호1', 
          col0: '1-1',
          col1: '유량-No', 
          col2: '원인1', 
          regul: '관련법규1',
          cause: '원인1',
          result: '결과1',
          col4: '안전조치사항1', 
          col6: '2021-07-27',
          col7: '최씨',
          userId: '1',
          col8: 'O', 
          col10: '1-1,1-2', 
          col11: '접수,요청', 
          col12: '개선대책1', 
          col30: '설계의도1', 
          col32: '1', 
          col33: '2', 
          col34: '2', 
          col36: '2', 
          col37: '2', 
          col38: '4', 
        },
        { 
          pidId: '6', 
          nodeNm: '노드1', 
          nodeNo: '노드번호1', 
          col0: '1-2',
          col1: '온도-No', 
          col2: '원인2', 
          regul: '관련법규2',
          cause: '원인2',
          result: '결과2',
          col4: '안전조치사항2', 
          col6: '2021-07-27',
          col7: '최씨',
          userId: '1',
          col8: 'X', 
          col10: '', 
          col11: '', 
          col12: '', 
          col30: '설계의도2', 
          col32: '1', 
          col33: '2', 
          col34: '2', 
          col36: '2', 
          col37: '2', 
          col38: '4', 
        },
        { 
          pidId: '1', 
          nodeNm: '노드2', 
          nodeNo: '노드번호2', 
          col0: '2-2',
          col1: '압력-No', 
          col2: '원인3', 
          regul: '관련법규3',
          cause: '원인1',
          result: '결과1',
          col4: '안전조치사항1', 
          col6: '2021-07-27',
          col7: '최씨',
          userId: '1',
          col8: 'O', 
          col10: '2-1,2-2,2-3', 
          col11: '접수,요청,조치중', 
          col12: '개선대책2', 
          col30: '설계의도3', 
          col32: '1', 
          col33: '2', 
          col34: '2', 
          col36: '2', 
          col37: '2', 
          col38: '4', 
        },
        { 
          pidId: '1', 
          nodeNm: '노드3', 
          nodeNo: '노드번호3', 
          col0: '3-1',
          col1: '온도-Low', 
          col2: '원인41', 
          regul: '관련법규4',
          cause: '원인1',
          result: '결과1',
          col4: '안전조치사항1', 
          col6: '2021-07-27',
          col7: '최씨',
          userId: '1',
          col8: 'X', 
          col10: '', 
          col11: '', 
          col12: '', 
          col30: '설계의도4', 
          col32: '1', 
          col33: '2', 
          col34: '2', 
          col36: '2', 
          col37: '2', 
          col38: '4', 
        },
        { 
          pidId: '1', 
          nodeNm: '노드3', 
          nodeNo: '노드번호3', 
          col0: '3-2',
          col1: '유량-No', 
          col2: '원인5', 
          regul: '관련법규5',
          cause: '원인2',
          result: '결과2',
          col4: '안전조치사항2', 
          col6: '2021-07-27',
          col7: '최씨',
          userId: '1',
          col8: 'X', 
          col10: '', 
          col11: '', 
          col12: '', 
          col30: '설계의도5', 
          col32: '1', 
          col33: '2', 
          col34: '2', 
          col36: '2', 
          col37: '2', 
          col38: '4', 
        },
        { 
          pidId: '1', 
          nodeNm: '노드3', 
          nodeNo: '노드번호3', 
          col0: '3-3',
          col1: '반응-As Well', 
          col2: '원인6', 
          regul: '관련법규6',
          cause: '원인3',
          result: '결과3',
          col4: '안전조치사항3', 
          col6: '2021-07-27',
          col7: '최씨',
          userId: '1',
          col8: 'X', 
          col10: '', 
          col11: '', 
          col12: '', 
          col30: '설계의도6', 
          col32: '1', 
          col33: '2', 
          col34: '2', 
          col36: '2', 
          col37: '2', 
          col38: '4', 
        },
        { 
          pidId: '2', 
          nodeNm: '노드4', 
          nodeNo: '노드번호4', 
          col0: '4-1',
          col1: '액면-No', 
          col2: '원인7', 
          regul: '관련법규7',
          cause: '원인1',
          result: '결과1',
          col4: '안전조치사항1', 
          col6: '2021-07-27',
          col7: '최씨',
          userId: '1',
          col8: 'X', 
          col10: '', 
          col11: '', 
          col12: '', 
          col30: '설계의도7', 
          col32: '1', 
          col33: '2', 
          col34: '2', 
          col36: '2', 
          col37: '2', 
          col38: '4', 
        },
        { 
          pidId: '2', 
          nodeNm: '노드5', 
          nodeNo: '노드번호5', 
          col0: '5-1',
          col1: '압력-Low', 
          col2: '원인8', 
          regul: '관련법규8',
          cause: '원인1',
          result: '결과1',
          col4: '안전조치사항1', 
          col6: '2021-07-27',
          col7: '최씨',
          userId: '1',
          col8: 'X', 
          col10: '', 
          col11: '', 
          col12: '', 
          col30: '설계의도8', 
          col32: '1', 
          col33: '2', 
          col34: '2', 
          col36: '2', 
          col37: '2', 
          col38: '4', 
        },
        { 
          pidId: '8', 
          nodeNm: '노드4', 
          nodeNo: '노드번호4', 
          col0: '4-1',
          col1: '압력-Low', 
          col2: '원인9', 
          regul: '관련법규7',
          cause: '원인1',
          result: '결과1',
          col4: '안전조치사항1', 
          col6: '2021-07-27',
          col7: '최씨',
          userId: '1',
          col8: 'X', 
          col10: '', 
          col11: '', 
          col12: '', 
          col30: '설계의도7', 
          col32: '1', 
          col33: '2', 
          col34: '2', 
          col36: '2', 
          col37: '2', 
          col38: '4', 
        },
        { 
          pidId: '8', 
          nodeNm: '노드5', 
          nodeNo: '노드번호5', 
          col0: '5-1',
          col1: '온도-High', 
          col2: '원인10', 
          regul: '관련법규8',
          cause: '원인1',
          result: '결과1',
          col4: '안전조치사항1', 
          col6: '2021-07-27',
          col7: '최씨',
          userId: '1',
          col8: 'X', 
          col10: '', 
          col11: '', 
          col12: '', 
          col30: '설계의도8', 
          col32: '1', 
          col33: '2', 
          col34: '2', 
          col36: '2', 
          col37: '2', 
          col38: '4', 
        },
      ]
    },
    changePid(value) {
      // 첫번째 인자는 공정코드, 두번째 인자는 도면id
      this.grid.data = this.$_.filter(this.grid.allData, { pidId: value[1] })
      
      this.pid = {
        nodeCnt: Math.floor(Math.random() * 30),
        riskbookCnt: Math.floor(Math.random() * 30),
        improvingCnt: Math.floor(Math.random() * 30),
        imporveCompleteCnt: Math.floor(Math.random() * 30),
      };
      this.chart1.series[0].data = [
        Math.floor(Math.random() * 250)
      ]
      this.chart1.series[1].data = [
        Math.floor(Math.random() * 250)
      ]
      this.chart2.series[0].data = [
        Math.floor(Math.random() * 120)
      ]
      this.chart2.series[1].data = [
        Math.floor(Math.random() * 120)
      ]
      this.chart3.series[0].data = [
        Math.floor(Math.random() * 250)
      ]
      this.chart3.series[1].data = [
        Math.floor(Math.random() * 250)
      ]
      this.setChart();
    },
    setChart() {
      setTimeout(() => {
        this.chart1.chartWidth = '100%';
        this.chart2.chartWidth = '100%';
        this.chart3.chartWidth = '100%';
      }, 200);
      this.$refs['chart1'].refresh();
      this.$refs['chart2'].refresh();
      this.$refs['chart3'].refresh();
    },
    linkClick(row, col, index) {
      // this.popupOptions.title = 'HAZOP 상세'; // HAZOP 상세
      // this.popupOptions.param = row;
      // this.popupOptions.target = () => import(`${'./hazopDetail.vue'}`);
      // this.popupOptions.visible = true;
      // this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    setSize() {
      this.height = window.innerHeight;
      this.grid.height = (this.height - 350) + 'px'
      this.$refs['hazopDashboard'].style.setProperty("--cascader-height-var", (this.height - 100) + 'px');
    },
    clickFullScreen() {
      let element = this.$refs['hazopDashboard']
      if (this.fullscreen === false) {
        this.fullscreen = true;
        let requestMethod = element.requestFullScreen || element.webkitRequestFullScreen || element.mozRequestFullScreen || element.msRequestFullScreen;

        if (requestMethod) { // Native full screen.
          requestMethod.call(element);
        }
      } else {
        this.fullscreen = false;
        let requestMethod = document.exitFullscreen || document.cancelFullScreen || document.webkitCancelFullScreen || document.mozCancelFullScreen || document.msExitFullscreen;
        if (requestMethod) { // Native full screen.
          requestMethod.call(document);
        }		
      }
    },
  }
};
</script>
<style lang="sass">
.el-cascader-menu__wrap
  height: calc(var(--cascader-height-var)) !important
.dashborad
  .customCardbody
    padding: 0px !important
    margin: 0px !important

.el-cascader-node
  padding: 0 30px 0 5px !important
.el-cascader-menu
  min-width: auto
.el-cascader-node__label
  padding: 0px !important
.grid-menu [class*=col-]
  border-width: 0 !important
  // border-right: #dee2e6 solid 0 !important
  // border-bottom: #dee2e6 solid 1px !important
  padding: 0px !important
.widget-chart
  padding: 5px !important
.widget-chart .widget-numbers
 margin: 12px !important
</style>
<style scoped>
.blinking {
  -webkit-animation:blink 1s ease-in-out infinite alternate;
  -moz-animation:blink 1s ease-in-out infinite alternate;
  animation:blink 1s ease-in-out infinite alternate;
}
@-webkit-keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@-moz-keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
</style>